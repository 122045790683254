//TOPメインヴィジュアル
$(function(){
  //init
  mainSlider();

  function mainSlider() {
    // let slider_wrap = $('.c-topSlider');
    let slider = $('.c-topSlider__list');
    let slider_item = $('.c-topSlider__item');
    let index = slider_item.length;
    let width_diff = 60;
    let firstPosition = -120;

    //各スライド配置ずらし
    for ( let i = 0; i < slider_item.length; i++ ) {
      let num = index + 1;
      slider_item.eq(i).css({
        left: width_diff * i + 'px',
        zIndex: num
      });

      index--;
    }
    slider.css('left', firstPosition);//初期位置

    //スライダー動作
    //スライド本体
    let timeline_body = new TimelineMax({
      repeat: -1,
      repeatDelay: 0
    });
    //スライダー画像
    let timeline_item = new TimelineMax({
      repeat: -1,
      repeatDelay: 0
    });
    timeline_body
    .to(slider, 2, {left: firstPosition - (width_diff * 1) + 'px', delay : 4})
    .to(slider, 2, {left: firstPosition - (width_diff * 2) + 'px', delay : 4})
    .to(slider, 2, {left: firstPosition - (width_diff * 3) + 'px', delay : 4})
    .to(slider, 2, {left: firstPosition - (width_diff * 4) + 'px', delay : 2})
    .add(TweenMax.set(slider, {left: firstPosition + 'px', immediateRender:false}), 0);//初期位置へリセット

    timeline_item
    .to('.c-topSlider__item:nth-child(1)', 2, {left: '-96%', opacity: 0, delay : 4})
    .to('.c-topSlider__item:nth-child(2)', 2, {left: '-96%', opacity: 0, delay : 4})
    .to('.c-topSlider__item:nth-child(3)', 2, {left: '-96%', opacity: 0, delay : 4})
    .to('.c-topSlider__item:nth-child(4)', 2, {left: '-96%', opacity: 0, delay : 2})
    .add(TweenMax.set('.c-topSlider__item:nth-child(1)', {left: '0', immediateRender:false}), 0)
    .add(TweenMax.set('.c-topSlider__item:nth-child(2)', {left: width_diff * 1 + 'px', immediateRender:false}), 0)
    .add(TweenMax.set('.c-topSlider__item:nth-child(3)', {left: width_diff * 2 + 'px', immediateRender:false}), 0)
    .add(TweenMax.set('.c-topSlider__item:nth-child(4)', {left: width_diff * 3 + 'px', immediateRender:false}), 0);//初期位置へリセット
  }

});